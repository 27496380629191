import React from 'react';
import MemberAssignment from './Assignment.js';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Modal from 'cccisd-modal';
import style from './style.css';

const Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    render() {
        return (
            <div>
                <h2>Member survey examples</h2>
                <div className={style.grid}>
                    <div className="card border-success mb-4">
                        <div className="card-header text-bg-success">English</div>
                        <div className="card-body">
                            <div className={style.grid}>
                                <MemberAssignment ageType="youth" language="en" />
                                <MemberAssignment ageType="teen" language="en" />
                            </div>
                        </div>
                    </div>

                    <div className="card border-info mb-4">
                        <div className="card-header text-bg-info">Spanish</div>
                        <div className="card-body">
                            <div className={style.grid}>
                                <MemberAssignment ageType="youth" language="es" />
                                <MemberAssignment ageType="teen" language="es" />
                            </div>
                        </div>
                    </div>
                </div>

                <h2>Staff survey example</h2>
                <Modal
                    title="Staff survey"
                    trigger={
                        <button id="demo-staff" type="button" className="btn btn-success btn-lg">
                            Staff Survey
                        </button>
                    }
                    size="large"
                    ref={elem => {
                        this.staffModal = elem;
                    }}
                >
                    <DeploymentPlayer
                        deploymentHandle="staff"
                        disableLayout
                        onComplete={() => this.staffModal.close()}
                        allowLanguageChange={false}
                        shouldSaveLanguage={false}
                        shouldSaveFlowList={false}
                        isPreviewMode
                        pawnId={Fortress.user.acting.id}
                        pawnHash={Fortress.user.acting.random_hash}
                    />
                </Modal>
            </div>
        );
    }
}
